import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`We`}</a>{` hosted a bot demo night last night at WSGR in San Francisco.`}</p>
    <p>{`It was great opportunity to see what people are doing with bots, across different platforms.`}</p>
    <h2>{`Dashbot`}</h2>
    <p>{`The night featured bots for travel, food discovery, team building, entertainment, and even ones for finding a date or finding a contractor… not the same bot! 🙂`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160057/0f0bc-18olniiri1_uiou29ymtwrq.png)
    </figure>
Our co-founder, Dennis, kicked off the night with an overview of
[Dashbot](http://www.dashbot.io/)’s analytics platform.
    <p>{`If you’re building a bot, check it out — `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`www.dashbot.io`}</a>{`. It’s a great way to learn more about your bot and increase user engagement and retention. We recently added additional metrics around retention and messaging statistics. We also launched live person injection which enables you to insert messages into the session stream as if you’re the bot. We continually add new features, so feel free to provide feedback and suggestions.`}</p>
    <h2>{`Demos`}</h2>
    <p>{`The bot demo line up included:`}</p>
    <p><a parentName="p" {...{
        "href": "http://alterra.ai/"
      }}><strong parentName="a">{`Alterra.ai`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160057/86f99-1khfkyzkidxsbvw9hj9sika.png)
    </figure>
Denis from Alterra demo’d how a user can discover new places to travel to as
well as book hotels, all through natural conversation. “I’d like a warmer place,
please…”
    <p><a parentName="p" {...{
        "href": "http://www.facebook.com/Blackjack-Bot-1040239006064521"
      }}><strong parentName="a">{`Blackjack`}</strong></a>{` `}<strong parentName="p">{`/ ChatbotCity`}</strong></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160056/9beb0-1evvooi2tzhy9fl_2pv3ljg.png)
    </figure>
Rhett, recently developed a blackjack game inside Messenger. It’s built on top
of his platform, ChatbotCity.
    <p>{`Games are an interesting area with bots. It will be interesting to see what developers and publishers come up with in terms of conversational games. Much like with mobile apps, how developers made use of gestures and even the microphone to make interesting games, there are probably some cool ideas that can be done in conversation. We could all keep playing `}<a parentName="p" {...{
        "href": "http://m.me/playzork"
      }}>{`Zork`}</a>{` too.`}</p>
    <p><a parentName="p" {...{
        "href": "http://bubby.love/"
      }}><strong parentName="a">{`Bubby`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160056/8e60d-185k6s7fsybbjamkcqwpyxw.png)
    </figure>
Stephanie from Bubby demo’d their Matchmaking SMS bot. Dating and relationships
seem like interesting areas for bots.
    <p><a parentName="p" {...{
        "href": "http://m.me/messinabot"
      }}><strong parentName="a">{`Chris Messina`}</strong></a>{` `}<strong parentName="p">{`Bot`}</strong></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160055/980bf-1pw8yu6j8sqomjyen_ahidg.png)
    </figure>
Ethan, Esther, and Chris demoed the Messina bot — a bot that enables people to
schedule appointments with Chris, ask for Product Hunt help, and get additional
news from Chris.
    <p>{`This is an interesting area for bots — personal bots to offload some common requests and tasks. We’ve seen others with `}<a parentName="p" {...{
        "href": "http://m.me/helloestherbot"
      }}>{`Esther’s`}</a>{` own bot as well as the `}<a parentName="p" {...{
        "href": "http://m.me/sarahatinovia"
      }}>{`Sarah`}</a>{` bot — there’s even a `}<a parentName="p" {...{
        "href": "http://m.me/askthecat"
      }}>{`cat`}</a>{` bot.`}</p>
    <p><a parentName="p" {...{
        "href": "http://m.me/foxsybot"
      }}><strong parentName="a">{`Foxsy`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160055/c8bb6-1x_jtxqwvxhjv7bfjufghng.png)
    </figure>
Yoyo demo’d the Foxsy bot — a tinder like dating bot.
    <p><a parentName="p" {...{
        "href": "http://www.growbot.io/"
      }}><strong parentName="a">{`Growbot`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160054/e132f-1fsctuisk-5iau9p1mizizq.png)
    </figure>
Justin demo’d growbot — a team feedback bot. Not only is it a way to provide
quick praise for a teammate, but you can start to learn more about the company’s
culture through the bot usage. Interestingly they originally had functionality
for not so positive feedback, but as Justin described, the workplace isn’t quite
ready for that…
    <p><a parentName="p" {...{
        "href": "http://www.pullstring.com/"
      }}><strong parentName="a">{`Jessie Humani / PullString`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160054/7db4d-19enrlqds2sawv5klpgfvqq.png)
    </figure>
Scott from Pullstring demoed Jessie Humani — this is a great example of an
entertainment bot. Users can interact with Jessie, a young lady with a lot
decisions to make. Having tried it, one of the nice things is the interaction
keeps flowing along fairly seamlessly even when your responses might not
completely match the conversation. You don’t get the “I don’t know” type
responses from Jessie.
    <p>{`Pullstring is also the creator of the Call of Duty bot which had over 6M messages in 24 hours.`}</p>
    <p><a parentName="p" {...{
        "href": "http://tara.ai/"
      }}><strong parentName="a">{`Tara.ai`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160053/35f82-1zkjqj_1chxi5vrpxphpswq.png)
    </figure>
Syed demo’d Tara.ai’s bot for outsourcing projects, like having a mobile app
built. The bot asks for the details of the project in a conversational manner
and then either provides a quote for the project, if the AI recognizes the specs
match similar past projects, or directs you to a project manager to complete the
process.
    <p><a parentName="p" {...{
        "href": "http://tastefulapp.com/"
      }}><strong parentName="a">{`Tasteful`}</strong></a></p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160053/914ce-1p3gwktjrsqzs95nkkrflrq.png)
    </figure>
Ravi demo’d Tasteful, a multi-platform bot for finding local restaurants.
    <h3>{`Bot Directory`}</h3>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2016/06/07160052/d8093-1cxssbs80mcyp6dee14psya.png)
    </figure>
Our site lists many of the Facebook and Slack bots available and we have a
Facebook Bot directory which enables you to discover, search for, and rate
Messenger bots — it’s available at <a href="http://m.me/dashbot.io" />
    <h3>{`Until Next Time…`}</h3>
    <p>{`The “Bot Space” has a vibrant community growing around it. We had a full house of attendees, many who stayed well past the official end to continue talking about bots.`}</p>
    <p>{`Stay tuned for our next event!`}</p>
    <hr></hr>
    <p><em parentName="p">{`Originally published at`}</em>{` `}<a parentName="p" {...{
        "href": "https://hackernoon.com/bot-demo-night-937a6b642dad"
      }}><em parentName="a">{`hackernoon.com`}</em></a>{` `}<em parentName="p">{`on June 30, 2016.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      